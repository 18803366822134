import Mensage from "../components/Project/Mensage";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";

export default function Projects(){
    return(
        <>
            <Header/>
            <section>
                
                <h1>Seus Projetos</h1>
                <Mensage Mensagem={false} />

            </section>
            <Footer />
        </>
    )
}