import Header from "../components/layout/Header"
import Footer from "../components/layout/Footer"
export default function Company(){
    return(
        <>
            <Header/>
                <main>
                    <div>coapania de petrolio</div>
                </main>
            <Footer/>
        </>
    )
}