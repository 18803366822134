import Header from "../components/layout/Header"
import Footer from "../components/layout/Footer"
export default function Contact(){
    return(
        <>
            <Header/>
            <main>
                <h1>CONTACT</h1>
            </main>
            <Footer/>
            
        </>
    )
}