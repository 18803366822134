import Header from "../components/layout/Header"
import Footer from "../components/layout/Footer"
import ProjectForm from "../components/Project/ProjectForm"
import { useNavigate } from "react-router-dom"
export default function NewProject(){
    const history = useNavigate()

    function createPost(project){
        project.cost=0
        project.services=[]

        fetch('http:localhost:5000/projects',{
            method:"POST",
            headers:{
                "content-type" : "application/json"
            }
        })
        .then((resp) => resp.json())
        .then((data) => console.log(data))
        .catch((err) => console.log(err))
    }

    return ( 
        <>
            <Header/>
            <section className="mt-3">
                <h1 >Novo Projeto</h1>
                <ProjectForm handleSubmit={createPost} />

            </section>
            <Footer/>
        </>
    )
}
