import './App.css';
import AppRoutes from './Routes';
import 'bootstrap/dist/css/bootstrap.min.css';





function App() {
  return (
    <div className="App">
      
      <AppRoutes />
      
    </div>
  );
}

export default App;
